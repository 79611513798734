.wrapper {
  @apply flex;
  @apply flex-col;
  @apply lg:flex-row;
  @apply justify-center;
  @apply items-center;
  @apply w-full;
  @apply bg-[#EBF0FA];
}

.image-wrapper {
  @apply h-[80vh];
  @apply flex;
  @apply w-auto;
  @apply lg:w-1/2;
  @apply lg:h-auto;
}

.image {
  @apply self-center;
  @apply object-contain;
  @apply object-center;
  @apply mx-auto;
}

.content-wrapper {
  @apply w-auto;
  @apply md:w-full;
  @apply lg:w-1/2;

  .content {
    @apply xl:max-w-[65%];
    @apply w-full;
    @apply mx-auto;
    @apply px-6;
    @apply lg:px-3;
  }

  .form {
    @apply mt-4;
    .heading {
      @apply text-4xl;
      @apply leading-10;
      @apply font-semibold;
      @apply mb-4;
    }
    .description {
      @apply prose;
      @apply prose-sm;
    }
  }
}

.step-one-button {
  @apply bg-primary;
  @apply px-8;
  @apply py-3;
  @apply mt-5;
  @apply text-sm;
  @apply font-semibold;
  @apply rounded-md;
  @apply transition-all;
  @apply duration-300;
  @apply ease-in;
  color: #f6f8f9;

  &:hover {
    @apply bg-opacity-80;
  }
}

.terms-spacer {
  @apply mt-2;
}

.text-wrapper {
  @apply w-full;
  @apply mt-1.5;
}

.show-more-less {
  @apply flex-col;
  &.wrapper {
    @apply text-xs;
    @apply mt-2;
  }

  &.anchor {
    @apply text-primary;
    @apply text-opacity-60;
  }
}

.mail {
  @apply text-primary;
}
