.root-wrapper {
  @apply min-w-full;
  @apply mt-4;
}

.label {
  @apply flex;
  @apply flex-col;
  @apply font-medium;
  @apply text-xs;

  &.screen-reader {
    @apply sr-only;
  }
}

.screen-reader {
  @apply sr-only;
}

.wrapper {
    @apply relative;
    @apply mt-1;
}

.button {
    @apply relative;
    @apply w-full;
    @apply cursor-default;
    @apply bg-white;
    @apply py-2;
    @apply pl-3;
    @apply pr-10;
    @apply text-left;
    @apply border-[#E7E7E8];
    @apply bg-[#F3F3F4];
    @apply rounded-md;
    @apply py-3;
    @apply px-4;
    @apply sm:text-sm;
}

.dob-wrapper {
    @apply relative;
    @apply cursor-pointer;
    @apply select-none;
    @apply py-3;
    @apply px-4;
}

.dob {
    @apply block;
    @apply truncate;
}

.icon-wrapper {
    @apply pointer-events-none;
    @apply absolute;
    @apply inset-y-0;
    @apply right-0;
    @apply flex;
    @apply items-center;
    @apply pr-2;
}

.icon {
    @apply h-6;
    @apply w-7 #{!important};
    @apply text-[#797E80];
}

.icon-rotate {
    @apply transform;
    @apply transition-transform;
    @apply duration-200;
    @apply ease-in;
    @apply rotate-180;
}

.options {
    @apply absolute;
    @apply z-20;
    @apply mt-2;
    @apply max-h-60;
    @apply w-full;
    @apply overflow-auto;
    @apply rounded-md;
    @apply bg-white;
    @apply py-1;
    @apply text-base;
    @apply ring-1;
    @apply ring-black;
    @apply ring-opacity-5;
    @apply focus:outline-none;
    @apply sm:text-sm;
}
