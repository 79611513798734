.root-wrapper {
  @apply min-w-full;
  @apply mt-2;
}

.wrapper {
  @apply relative;
}

.label {
  @apply block;
  @apply mb-1;
  @apply text-xs;
  @apply font-medium;
}

.input {
  @apply bg-gray-50;
  @apply border;
  @apply border-gray-300;
  @apply text-sm;
  @apply rounded-lg;
  @apply block;
  @apply w-full;
  @apply p-2.5;
  @apply focus:ring-blue-500;
  @apply focus:border-blue-500;
}

.options {
  @apply absolute;
  @apply z-20;
  @apply mt-1;
  @apply max-h-60;
  @apply w-full;
  @apply overflow-auto;
  @apply rounded-md;
  @apply bg-white;
  @apply py-1;
  @apply shadow-lg;
  @apply ring-1;
  @apply ring-black;
  @apply ring-opacity-5;
  @apply text-sm;
  @apply focus:outline-none;
}

.not-available {
  @apply relative;
  @apply cursor-not-allowed;
  @apply select-none;
  @apply py-2;
  @apply px-4;
}

.error {
  @apply text-red-500;
  @apply text-xs;
  @apply italic;
}
