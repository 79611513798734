.survey-wrapper {
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.modal-icon {
  @apply rounded-md;
  @apply text-sm;
  @apply font-medium;
  @apply text-white;
  @apply hover:bg-opacity-30;
  @apply focus:outline-none;

  &.icon {
    @apply w-5;
    @apply h-5;
    @apply text-primary;
  }
}

.dialog-index {
  @apply relative;
  @apply z-30;
}

.background-dim {
  @apply fixed;
  @apply inset-0;
  @apply bg-black;
  @apply bg-opacity-30;
}

.content-wrapper {
  @apply fixed;
  @apply inset-0;
  @apply overflow-y-auto;

  
}

.content {
  @apply flex;
  @apply min-h-full;
  @apply items-center;
  @apply justify-center;
  @apply p-4;
  @apply text-center;
}

.panel {
  @apply w-full;
  @apply max-w-md;
  @apply transform;
  @apply overflow-hidden;
  @apply rounded-2xl;
  @apply bg-white;
  @apply text-left;
  @apply align-middle;
  @apply shadow-xl;
  @apply transition-all;
}

.panel-content {
  @apply flex;
  @apply justify-between;
  @apply p-5;

  .title {
    @apply text-lg;
    @apply leading-6;
    @apply text-kanarys-black;
    @apply font-semibold;
  }

}

.close-icon {
  @apply w-5;
  @apply h-5;
  @apply text-kanarys-black;
  @apply cursor-pointer;

  &.center {
    @apply self-center;
  }
}

.form {
  @apply flex;
  @apply justify-between;
  @apply w-full;
  @apply px-6;

  .input {
    @apply ring-kanarys-black;
    @apply border-kanarys-black;
    @apply rounded;
    @apply pl-4;
    @apply py-3;
    @apply text-sm;
    @apply placeholder:text-kanarys-secondary;
    @apply text-kanarys-black;
    @apply md:w-4/6;
  }

  .invite {
    @apply bg-gray-400;
    @apply text-kanarys-border;
    @apply py-3;
    @apply px-4;
    @apply rounded-md;
    @apply cursor-auto;
    @apply max-h-12;
  }
}
.invite-primary {
  @apply bg-primary #{!important};
  @apply text-white #{!important};
  @apply cursor-pointer #{!important};
}

.email_wrapper {
  @apply px-5;
  @apply grid;
  @apply grid-cols-3;
  @apply gap-y-2;
  @apply gap-x-0.5;
  @apply mt-4;
}
.emails {
  @apply flex;
  @apply items-center;
  @apply justify-between;
  @apply border-gray-200;
  @apply border;
  @apply p-0.5;
  @apply rounded-md;
  @apply text-xs;

  .email_address {
    @apply pl-0.5;
    @apply tracking-tighter;
    @apply line-clamp-1;
  }

  .icon{
    @apply self-center;
    @apply w-5;
    @apply h-5;
    @apply cursor-pointer;
  }
}

.error {
  @apply font-semibold;
  @apply text-red-700;
  @apply ml-6;
  @apply text-2xs;
}



.copy-link-wrapper {
  @apply mt-4;
  @apply flex;
  @apply space-x-2;
  @apply border-t;
  @apply py-3;

  .link-icon {
    @apply w-4;
    @apply h-4;
    @apply text-primary;
    @apply ml-6;
    @apply cursor-pointer;
  }

  .copy-link {
    @apply text-primary;
    @apply capitalize;
    @apply text-xs;
  }
}

.button {
  @apply mx-auto;
  @apply bg-primary;
  @apply text-white;
  @apply rounded;
  @apply px-6;
  @apply py-3;
  @apply text-sm;
  @apply transition-all;
  @apply duration-300;
  @apply ease-in;
  @apply disabled:opacity-50;
  @apply hover:bg-opacity-80;
  max-width: 6rem;

  &.semi {
    @apply font-semibold;
  }

  &.cap {
    @apply uppercase;
  }
}

.login-wrapper {
  @apply bg-white;
  @apply flex;
  @apply flex-col;
  @apply rounded-md;
  @apply space-y-2;
  @apply max-w-xs;
  @apply w-full;
  @apply h-full;
  @apply p-10;
  @apply shadow-lg;
}

.heading-wrapper{
  @apply flex;
  @apply justify-between;
  @apply -mb-1;
}

.heading {
  @apply text-2xl;
  @apply tracking-tight;
  @apply flex-1;
  @apply font-medium;
  @apply text-gray-800;
}

.helper-text {
  @apply font-medium;
  @apply text-2xs;
  @apply tracking-tighter;
  @apply -mt-2;
}

.login_input {
  @apply p-2;
  @apply border;
  @apply border-gray-300;
  @apply rounded;
  @apply block;
  @apply w-full;
  @apply mx-auto;
  @apply mt-1;
  @apply text-sm;
}

.login_error {
  @apply text-2xs;
  @apply text-red-700;
  @apply italic;
  @apply text-left;
  @apply flex;
  @apply justify-start;
}
