.wrapper {
    @apply h-screen;
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply items-center;
    @apply tracking-tight;
    @apply font-medium;
    @apply text-sm;
    @apply md:text-base;
    @apply p-4;
    @apply space-y-4;
    @apply bg-primary;
}

.image {
    @apply w-60;
    @apply h-16;
    @apply object-contain;
}

.path {
  @apply italic;
  @apply text-gray-300;
}

.heading {
  @apply text-2xl;
}

.text-color {
  @apply text-gray-100;
}

.support {
  @apply italic;
  @apply underline;
}

.redirect {
  @apply uppercase;
  @apply underline;
  @apply tracking-wide;
  @apply font-semibold;
}
