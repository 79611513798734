// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply scroll-smooth;
}
body {
  @apply font-NotoSans;
  @apply bg-krs-gray-200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
