h3 {
  @apply text-xl;
  @apply font-semibold;
  @apply text-gray-800;
}
.wrapper {
  @apply container;
  @apply flex;
  @apply flex-col;
  @apply mx-auto;
  @apply space-y-6;
  @apply p-4;
  @apply md:px-2;
  @apply lg:px-0;

}

.footer_upper {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply space-y-4;
  @apply text-center;
  @apply md:flex;
  @apply md:space-y-0;
  @apply md:flex-row;
  @apply md:justify-between;
  @apply md:items-center;
  @apply md:text-left;

  > div {
    @apply md:self-baseline;
  }

  .image {
    @apply h-8;
    @apply self-center;
    @apply md:self-start;
    @apply -mt-1;
  }
}

.upper_items {
  @apply flex;
  @apply flex-col;
  @apply space-y-2;
  @apply md:space-y-1;

}

.footer_lower {
  @apply flex;
  @apply flex-col;
  @apply md:flex-row;
  @apply justify-between;
  @apply items-center;
  @apply space-y-4;
  @apply text-center;
  @apply md:space-y-0;
  @apply md:text-left;

  .client_login {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply md:justify-start;
    @apply w-full;
    @apply md:w-auto;
    @apply py-2;
    @apply px-6;
    @apply font-medium;
    @apply cursor-pointer;
    @apply bg-[#2F435B];
    @apply rounded-lg;
    @apply text-gray-100;
    @apply hover:bg-opacity-90;
    @apply transition-all;
    @apply duration-300;
    @apply ease-in;

    &.logged_in {
      @apply hidden;
    }
  }

  .right {
    .items {
        @apply text-gray-700;
        @apply flex;
        @apply flex-col;
        @apply md:flex-row;
        @apply items-center;
        @apply justify-center;
        @apply space-y-2;
        @apply md:space-y-0;
        @apply md:space-x-4;

        & li::before {
            content: '|'; 
            @apply text-gray-400;
            @apply text-sm;
            @apply font-medium;
            @apply mr-4;
            @apply hidden;
            @apply md:inline-block;

            &:first-child {
                @apply hidden;
            }
        }
    }
  }
}
