.wrapper {
  @apply grid;
  @apply grid-cols-1;
  @apply lg:grid-cols-2;
  @apply xl:grid-cols-3; 
  @apply gap-2;
  @apply lg:gap-4;
  @apply xl:gap-6;
  @apply mt-4;
}

.card {
  @apply flex;
  @apply justify-start;
  @apply items-center;
  @apply border-gray-400;
  @apply border-2;
  @apply space-x-4;
  @apply py-9;
  @apply px-4;
  @apply rounded-lg;
  @apply text-krs-gray-800;
  @apply text-base;
  @apply font-bold;
  @apply transition-all;
  @apply duration-300;
  @apply ease-in;
  @apply tracking-tight;


  &:hover {
    @apply border-krs-gray-700;
    @apply bg-krs-gray-700;
    @apply cursor-pointer;

    h3 {
      @apply text-white;
    }
  }
}

.icon_background {
  @apply w-14;
  @apply h-14;
  @apply rounded-full;
  @apply relative;
  @apply bg-[#F3B43F];
}

.icon {
  @apply absolute;
  @apply top-1/2;
  @apply right-1/2;
  @apply transform;
  @apply translate-x-1/2;
  @apply -translate-y-1/2;
  @apply w-6;
  @apply h-6;
  @apply text-white;
}


