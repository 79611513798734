.wrapper {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply md:justify-around;
  @apply space-y-4;
  @apply p-4;
  @apply bg-gray-200;
  @apply h-screen;
}

.content {
  @apply w-full;
  @apply lg:w-2/5;
  @apply xl:w-1/3;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply space-y-6;
  @apply bg-white;
  @apply py-28;
  @apply rounded-xl;
}

.heading_content {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply space-y-4;
  @apply text-center;
  @apply mx-auto;

  & > p {
    @apply text-gray-500;
    @apply max-w-sm;
  }
}

.heading {
  @apply text-3xl;
  @apply text-gray-900;
  @apply font-NotoSans;
  @apply font-bold;
  @apply tracking-tight;
  @apply relative;

}

.form {
  @apply w-full;
  @apply mx-auto;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply space-y-4;

  & > div {
    @apply w-4/5;
    @apply lg:w-4/5;
    /* @apply lg:w-2/3; */
  }
}

.label {
  @apply block;
  @apply text-sm;
  @apply font-medium;
  @apply text-gray-700;
  @apply cursor-pointer;
}

.inputs {
  @apply p-2;
  @apply mt-1;
  @apply focus:ring-indigo-200;
  @apply focus:border-indigo-200;
  @apply w-full;
  @apply h-9;
  @apply shadow-sm;
  @apply sm:text-sm;
  @apply border-gray-400;
  @apply rounded-md;
  @apply text-gray-900;
  @apply bg-gray-100;
  @apply font-semibold;

  &::placeholder {
    @apply text-gray-400;
  }
}

.col {
    @apply flex;
    @apply items-center;

    &.between {
      @apply justify-between;
    }
}

.remember {
  @apply h-4;
  @apply w-4;
  @apply text-primary;
  @apply focus:ring-indigo-500;
  @apply border-gray-300;
  @apply rounded-lg;
  @apply cursor-pointer;
}

.remember_label {
  @apply ml-2;
  @apply block;
  @apply text-sm;
  @apply text-gray-700;
  @apply tracking-tighter;
  @apply cursor-pointer;
}

.forgot {
  @apply font-medium;
  @apply text-sm;
  @apply text-primary;
  @apply hover:text-indigo-500;
  @apply self-end;
  @apply tracking-tight;
}

.button {
  @apply flex;
  @apply justify-around;
  @apply px-8;
  @apply py-1.5;
  @apply text-gray-100;
  @apply bg-gray-900;
  @apply mx-auto;
  @apply rounded-md;
  @apply border;
  @apply font-semibold;
  @apply hover:bg-opacity-90;
  @apply transition-all;
  @apply ease-in;
  @apply duration-300;
  @apply shadow-sm;
  @apply text-gray-100;
  @apply w-full;
  @apply mt-2;
  @apply lg:mt-4;

  &:hover {
    @apply border;
    @apply bg-transparent;
    @apply text-gray-900;
  }
}

.popup {
    @apply text-xs;
    @apply text-white;
}

.error {
  @apply text-red-500;
  @apply font-semibold;
  @apply text-xs;
  @apply mt-1;
}
