.question-heading {
  @apply text-sm;
  @apply text-center;
  @apply text-[#B6B9BA];
  @apply flex;
  @apply justify-center;

  &.left {
    @apply text-left;
    @apply justify-start;
    @apply ml-6;
  }
}

.content {
  @apply flex;
  @apply justify-center;
  @apply space-x-2;

  &.left {
    @apply justify-start #{!important};
  }

  > div {}
}

.label {
  @apply font-semibold;
  @apply text-2xl;
  @apply text-center;
  @apply mb-8;

  &.left {
    @apply text-left;
    @apply text-lg;
    @apply my-2;;
    @apply mx-0;
    @apply ml-6;
  }
}
