/* Mobile Navigation Starts */

.mobile_wrapper {
  @apply relative;
  @apply z-40;
  @apply md:hidden;
}

.dim_effect {
  @apply fixed;
  @apply inset-0;
  @apply bg-krs-gray-600;
  @apply bg-opacity-75;
}

.mobile_menu {
  @apply fixed;
  @apply inset-0;
  @apply z-40;
  @apply flex;
}

.mobile_nav {
  @apply relative;
  @apply flex;
  @apply w-full;
  @apply max-w-xs;
  @apply flex-1;
  @apply flex-col;
  @apply bg-krs-gray-800;
}

.hamburger_wrapper {
  @apply absolute;
  @apply top-0;
  @apply right-0;
  @apply -mr-12;
  @apply pt-2;
}

hamburger {
  @apply ml-1;
  @apply flex;
  @apply h-10;
  @apply w-10;
  @apply items-center;
  @apply justify-center;
  @apply rounded-full;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-inset;
  @apply focus:ring-white;
}

.accessible {
  @apply sr-only;
}

.hamburger_icon {
  @apply h-6;
  @apply w-6;
  @apply text-white;
}

.mobile_nav_content {
  @apply h-0;
  @apply flex-1;
  @apply overflow-y-auto;
  @apply pt-5;
  @apply pb-4;
}

.mobile_nav_logo {
  @apply flex;
  @apply flex-shrink-0;
  @apply items-center;
  @apply px-4;

  img {
    @apply h-8;
    @apply w-auto;
  }
}

.mobile_nav_list {
  @apply mt-5;
  @apply px-2;
  @apply space-y-1;
}

.mobile_nav_item{
  @apply flex;
  @apply items-center;
  @apply px-2;
  @apply py-2;
  @apply text-base;
  @apply font-medium;
  @apply rounded-md;
  @apply text-krs-gray-300;
  @apply hover:bg-krs-gray-700;
  @apply hover:text-white;
}

.mobile_nav_item.active {
  @apply bg-black;
  @apply text-white;
}

.mobile_nav_icon {
  @apply mr-4;
  @apply h-6;
  @apply w-6;
  @apply text-krs-gray-400;
  @apply hover:text-krs-gray-300;
}

.mobile_nav_icon_active {
  @apply text-krs-gray-300;
}

.footer {
  @apply flex;
  @apply flex-shrink-0;
  @apply bg-krs-gray-700;
  @apply p-4;
}

.shrink {
  @apply w-14;
  @apply flex-shrink-0;
}

/* Mobile Navigation Ends */

/* Desktop Navigation Starts */

.desktop_wrapper {
  @apply hidden;
  @apply md:fixed;
  @apply md:inset-y-0;
  @apply md:flex;
  @apply md:w-64;
  @apply md:flex-col;
}

.desktop_content {
  @apply flex;
  @apply min-h-0;
  @apply flex-1;
  @apply flex-col;
  @apply bg-white;
}

.desktop_nav {
  @apply flex;
  @apply flex-1;
  @apply flex-col;
  @apply overflow-y-auto;
  @apply pt-5;
  @apply pb-4;
}

.desktop_nav_logo {
  @apply flex;
  @apply flex-shrink-0;
  @apply items-center;
  @apply px-4;
}

.desktop_nav_image {
  @apply h-8;
  @apply w-auto;
}

.desktop_nav_lists {
  @apply mt-5;
  @apply flex-1;
  @apply space-y-1;
  @apply px-2;
}

.desktop_nav_item {
  @apply flex;
  @apply items-center;
  @apply px-2;
  @apply py-2;
  @apply text-sm;
  @apply font-medium;
  @apply rounded-md;
  @apply text-krs-gray-800;
  @apply hover:bg-krs-gray-200;

  &.active {
    @apply bg-krs-gray-200;
    @apply font-bold;
  }
}

.desktop_nav_icon {
  @apply mr-3;
  @apply h-6;
  @apply w-6;
  @apply text-krs-gray-800;
  @apply hover:text-krs-gray-300;

  &.active {
  }
}

.desktop_footer_list {
  @apply flex;
  @apply flex-wrap;
  @apply items-center;

  li {
    @apply flex;
    @apply items-center;
    @apply px-2;
    @apply text-sm;
    @apply text-krs-gray-600;
    @apply hover:underline;
  }
}

.copyright {
  @apply mt-5;
  @apply px-2;
  @apply text-krs-gray-500;
  @apply text-sm;
}

/* Desktop Navigation Ends */

/* Main Content Starts */

.main_content {
  @apply flex;
  @apply flex-1;
  @apply flex-col;
  @apply md:pl-64;
}

.main {
  @apply flex-1;
}

.main_bar {
  @apply sticky;
  @apply top-0;
  @apply z-10;
  @apply bg-gray-100;
  @apply pl-1;
  @apply pt-1;
  @apply sm:pl-3;
  @apply sm:pt-3;
  @apply md:hidden;
}

.bar_button {
  @apply -ml-0.5;
  @apply -mt-0.5;
  @apply inline-flex;
  @apply h-12;
  @apply w-12;
  @apply items-center;
  @apply justify-center;
  @apply rounded-md;
  @apply text-krs-gray-500;
  @apply hover:text-black;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-inset;
  @apply focus:ring-indigo-500;
}

.main_helper_pad {
  @apply py-6;
}

.main_heading {
  @apply mx-auto;
  @apply px-4;
  @apply sm:px-6;
  @apply lg:px-8;
}

.main_heading_title {
  @apply text-2xl;
  @apply font-semibold;
  @apply text-black;
}

.tab_wrapper {
  @apply mx-auto;
  @apply px-4;
  @apply sm:px-6;
}

.tab_list {
  @apply flex;
  @apply justify-start;
  @apply items-center;
  @apply space-x-4;
  @apply border-b;
}

.tab_item {
  @apply capitalize;
  @apply font-semibold;
  @apply text-krs-gray-600;
  @apply font-semibold;
  @apply py-4;
  @apply pr-4;
  @apply pl-2;

  &.active {
    @apply text-krs-gray-800;
    @apply border-krs-gray-800;
    border-bottom-width: 3px;
  }
}

.tab_content {
  @apply flex;
  @apply space-x-4;
  @apply mt-4;
  @apply ml-1;

  & > span {
    @apply font-medium;
    @apply text-krs-gray-800;
    @apply bg-krs-gray-200;
    @apply py-2;
    @apply px-3;
    @apply rounded-md;
    @apply cursor-pointer;

  }

}

  .osa {
    @apply bg-krs-gray-800 #{'!important'};
    @apply text-gray-100 #{'!important'};
  }

/* Main Content Ends */


