.wrapper {
  @apply container;
  @apply mx-auto;
  @apply p-4; 
  /* height: calc(90vh - 13.25rem); */
  @apply h-auto;
  @apply md:h-screen;
}

.content {
  @apply flex;
  @apply flex-col;
  @apply md:flex-row;
  @apply justify-between;
  @apply items-center;

  .left {
    @apply flex;
    @apply flex-col;
    @apply space-y-4;
  }

  .right {
    @apply flex;
    @apply justify-end;
    @apply items-center;

    .lottie_wrapper {
      @apply relative;
      @apply w-auto;
      @apply h-auto;
      @apply md:w-96;
      @apply md:h-96;
      @apply object-contain;
    }
  }
}

.heading {
  @apply text-4xl;
  @apply text-gray-700;
  @apply w-auto;
  @apply lg:w-5/6;
  @apply xl:w-4/6;
  @apply font-normal;

  &.strong {
    @apply font-bold;
    @apply text-gray-900;
  }
}

.scroll {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply h-4/5;

  & p {
    @apply text-sm;
    @apply font-medium;
    @apply text-gray-500;
  }
}
