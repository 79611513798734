.gradient {
  background-color: #f5f5f5;
}
.wrapper {
  @apply container;
  @apply mx-auto;
  @apply w-full;
  @apply py-12;
  @apply p-4;
  @apply text-[#2F435B];
}


.touo_head{
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-end;
  @apply space-y-4; 
  @apply h-auto;
  @apply md:h-[50vh];

  & img {
    @apply h-14;
  }

  .head {
    @apply text-xl;
    @apply md:text-3xl;
    @apply lg:text-5xl;
    @apply drop-shadow-lg;
    @apply uppercase;
    @apply tracking-wide;
    @apply font-semibold;
 
  }
  .span {
    @apply text-base;
    @apply md:text-lg;
    @apply tracking-tight;
    @apply font-semibold;
    @apply underline;
    @apply mb-4;
  }
}

.tou_list {
  @apply flex;
  @apply justify-around;
  @apply items-center;
  @apply h-auto;
  @apply md:h-14;


  & li a {
    @apply text-base;
    @apply md:text-lg;
    @apply tracking-tight;
    @apply font-semibold;
    /* @apply mb-4; */
    @apply text-gray-100;
    @apply bg-[#2F435B];
    @apply p-4;
  }
}

.touo_body {
  @apply md:flex;
  @apply md:my-6;
  @apply md:space-x-12;
}

.aside {
  @apply flex-1;
  @apply rounded-md;
  @apply drop-shadow-md;
  @apply p-4;
  @apply mb-4;
  @apply md:mb-0;
  @apply h-auto;
  // Sticky
  @apply md:sticky;
  @apply md:top-4;
  @apply md:overflow-y-auto;
  background: rgb(245,245,245);
  background: linear-gradient(90deg, rgba(245,245,245,1) 35%, rgba(237,237,237,1) 100%);
}

.main {
  @apply w-full;
  @apply md:w-2/4;
  @apply lg:w-3/4;
  @apply p-4;
  @apply text-[#1c2135];
  @apply flex;
  @apply flex-col;
  @apply space-y-4;
  
}

.items {
  @apply flex;
  @apply flex-col;
  @apply space-y-3;
  @apply tracking-tighter;
}

.content_head {
  @apply text-xl;
  @apply md:text-2xl;
  @apply font-semibold;
}
.content {
  @apply text-lg;
  @apply prose;
}

.lottie {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;

}

.lottie_wrapper{
  @apply relative;
  @apply overflow-hidden;
  @apply object-contain;
  @apply w-32;
}
