section {
  @apply sticky;
  @apply top-0;
  @apply z-50;
}

.wrapper {
  @apply container;
  @apply mx-auto;
  @apply p-2;
}

.content {
  @apply relative;
  @apply flex;
  @apply items-center;
  @apply justify-between;
  @apply h-auto;
}

.mobile_menu_panel {
  @apply sm:hidden;
}

.mobile_menu_content {
  @apply px-2;
  @apply pt-2;
  @apply pb-3;
  @apply space-y-1;
}

.mobile_menu {
  @apply absolute;
  @apply inset-y-0;
  @apply left-0;
  @apply flex;
  @apply items-center;
  @apply sm:hidden;
}

.mobile_menu_item {
  @apply text-white;
  @apply bg-gray-700;
  @apply hover:text-white;
  @apply block;
  @apply px-3;
  @apply py-2;
  @apply rounded-md;
  @apply text-base;
  @apply font-medium;

  &.active {
    @apply text-white;
    @apply bg-gray-900;
  }

}

.hamburger {
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply p-2;
  @apply rounded-md;
  @apply text-primary;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-inset;
  @apply focus:ring-white;

  .open {
    @apply block;
    @apply w-6;
    @apply h-6;
  }

  .close {
    @apply block;
    @apply w-6;
    @apply h-6;
  }
}

.desktop_menu {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply flex-1;
  @apply sm:items-stretch;
  @apply sm:justify-start;

  .logo {
    @apply flex;
    @apply items-center;
    @apply flex-shrink-0;

      .image_mobile {
        @apply block;
        @apply w-auto;
        @apply h-8;
        @apply lg:hidden;
      }

      .image_desktop {
        @apply hidden;
        @apply w-auto;
        @apply h-8;
        @apply lg:block;
      }
  }

  .desktop_nav {
      @apply hidden;
      @apply sm:ml-6;
      @apply sm:block;

      .items {
        @apply flex;
        @apply space-x-4;
      }
  }
}

.desktop_main_bar {
  @apply absolute;
  @apply inset-y-0;
  @apply right-0;
  @apply flex;
  @apply items-center;
  @apply pr-2;
  @apply sm:static;
  @apply sm:inset-auto;
  @apply sm:ml-6;
  @apply sm:pr-0;
}

.desktop_main_menu {
  @apply relative;
  @apply ml-3;
}

.menu_button {
  @apply flex;
  @apply text-sm;
  @apply bg-gray-800;
  @apply rounded-full;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-white;
  @apply focus:ring-offset-2;
  @apply focus:ring-offset-gray-800;
}

.main_menu_image {
  @apply w-8;
  @apply h-8;
  @apply rounded-full;
}

.main_menu_items {
  @apply absolute;
  @apply right-0;
  @apply z-10;
  @apply w-48;
  @apply py-1;
  @apply mt-2;
  @apply origin-top-right;
  @apply bg-white;
  @apply rounded-md;
  @apply shadow-lg;
  @apply ring-1;
  @apply ring-black;
  @apply ring-opacity-5;
  @apply focus:outline-none;
}

.main_menu_item {
  @apply block;
  @apply px-4;
  @apply py-2;
  @apply text-sm;
  @apply text-gray-700;
  @apply hover:bg-gray-100;

  .active {
    @apply bg-gray-100;
  }
}

.login {
  @apply px-9;
  @apply py-2;
  @apply font-bold;
  @apply text-base;
  @apply text-gray-100;
  @apply rounded-lg;
  @apply bg-krs-primary-dark;
}
