.root-wrapper{
  @apply bg-white;
}

.upper-wrapper {
  @apply container;
  @apply mx-auto;
  @apply p-4;
  @apply lg:p-2;
  @apply lg:py-12;
}

.page-title {
  @apply font-semibold;
  @apply text-xl;
  @apply md:text-2xl;
}

.progress-wrapper {
  @apply w-full;
  @apply bg-gray-200;
  @apply rounded-full;
  @apply dark:bg-gray-700;
  @apply my-4;

  .progressbar {
    @apply bg-primary;
    @apply text-xs;
    @apply font-medium;
    @apply text-blue-100;
    @apply text-center;
    @apply p-0.5;
    @apply leading-none;
    @apply  rounded-full;
  }
}

.progress-percent {
  @apply font-medium;
  @apply text-sm;
}

.pagination {
  @apply w-full;
  @apply flex;
  @apply justify-between;

  &.prev {
    @apply text-left;
  }
}

.wrapper {
  @apply container;
  @apply mx-auto;
  @apply flex;
  @apply flex-col;
  @apply md:flex-row;
  @apply justify-between;
  @apply space-x-0;
  @apply md:space-x-4;
  @apply px-4;
  @apply lg:px-1;
}

.sidebar {
  @apply w-auto;
  @apply md:w-1/4;
  @apply h-auto;
  @apply md:h-screen;
  @apply sticky;
  @apply top-3;
  @apply border-r;

  .items {
    @apply flex;
    @apply items-center;
    @apply py-3;
    @apply my-1;
    @apply px-1;
    @apply rounded-lg;
    @apply cursor-pointer;
    @apply mr-1;
    @apply md:mr-3;

    &.grayed {
      @apply bg-gray-200 #{!important};
    }

    .icon {
      @apply w-1/6;
      @apply h-6;
      @apply text-gray-300;

      &.active {
        @apply text-primary;
      }
    }

    .title {
      @apply w-4/6;
      @apply ml-2;
      @apply text-sm;
    }

    .modal {
      @apply w-1/6;
      @apply self-baseline;
    }
  }
}

.questions-root-wrapper {
  @apply w-auto;
  @apply flex-1;
}

.questions-wrapper {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply overflow-auto;
  @apply mt-4;
  @apply min-h-[30rem];

  &.form-wrapper {
    @apply items-start;
    @apply overflow-hidden;
  }
}

.question-helper-buttons {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply pb-4;
  @apply md:pb-12;
}

.render-questions {
  @apply flex;
  @apply flex-col;
  @apply justify-start;
  @apply space-y-10;
  @apply w-full;
}

.button {
  @apply bg-primary;
  @apply relative;
  @apply z-20;
  @apply text-white;
  @apply rounded;
  @apply px-8;
  @apply py-3;
  @apply text-sm;
  @apply capitalize;
  @apply opacity-100;
  @apply transition-all;
  @apply duration-300;
  @apply ease-in;
  @apply disabled:opacity-50;
  @apply hover:bg-opacity-80;

  &.hidden{
    @apply opacity-0;
  }

  &.col {
    @apply flex;
    @apply justify-center;
    @apply items-center;
  }

  .icon {
    @apply w-3;
    @apply h-4;
  }

  .icon {
    @apply w-5;
    @apply h-6;
  }

  &.space {
    @apply space-x-3;
  }

  &.space-1{
      @apply space-x-1;
  }

  &.semi {
    @apply font-semibold;
  }

  &.transparent {
    @apply bg-transparent;
    @apply text-primary;
    @apply p-0;
  }

  &.cap {
    @apply uppercase;
  }
}
