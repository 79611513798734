.root-wrapper {
  @apply min-w-full;
  @apply mt-4;
}

.label {
  @apply block;
  @apply mb-2;
  @apply text-xs;
  @apply font-medium;
}

.label-spacer {
  @apply ml-1;
}

.input {
  @apply bg-gray-50;
  @apply border;
  @apply border-gray-300;
  @apply text-sm;
  @apply rounded-lg;
  @apply block;
  @apply w-full;
  @apply p-2.5;
  @apply focus:ring-blue-500;
  @apply focus:border-blue-500;
}

.error {
  @apply text-red-500;
  @apply text-xs;
  @apply italic;
}
