.wrapper {
  @apply container;
  @apply mx-auto;
  @apply px-4;
  @apply xl:px-0;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;
  @apply mt-16;
}

.wrapper {
  @apply w-full;
}

.wrapper-resize {
  @apply w-full;
  @apply h-auto;
}

.upper {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply my-4;
}

.heading {
  @apply text-2xl;
  @apply font-semibold;
}

.progress-wrapper {
  @apply mb-4;
  @apply flex;
  @apply justify-start;
  @apply items-center;
  @apply space-x-2;
}

.progress-bar {
  @apply w-full;
  @apply bg-gray-200;
  @apply rounded-full;
  @apply h-2.5;
  @apply dark:bg-gray-700;

  // &.active-bar {
  //   @apply bg-primary;
  // }
}

.controls {
  @apply flex;
  @apply justify-between;
  @apply mb-4;
}

.questionnaire {
  @apply text-sm;
  @apply font-semibold;
}

.button {
  @apply bg-primary;
  @apply relative;
  @apply z-20;
  @apply text-white;
  @apply rounded;
  @apply px-6;
  @apply py-3;
  @apply text-sm;
  @apply transition-all;
  @apply duration-300;
  @apply ease-in;
  @apply disabled:opacity-50;
  @apply hover:bg-opacity-80;

  &.col {
    @apply flex;
    @apply justify-center;
    @apply items-center;
  }

  .icon {
    @apply w-5;
    @apply h-6;
  }

  .prev {
    @apply font-medium;
  }

  .icon {
    @apply w-5;
    @apply h-6;
  }

  &.space {
    @apply space-x-3;
  }

  &.space-1 {
    @apply space-x-1;
  }

  &.semi {
    @apply font-semibold;
  }

  &.transparent {
    @apply bg-transparent;
    @apply text-primary;
    @apply p-0;
  }

  &.cap {
    @apply uppercase;
  }
}

.survey-first-level {
  @apply w-auto;
  @apply mx-auto;
  @apply mt-14;
  @apply px-4;
  @apply overflow-auto;
  @apply md:max-w-3xl;
  @apply lg:px-2;
  height: 65vh;
}

/** ---------------- Helper Text -------------------- */
  .helper-message {
    @apply h-screen;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply tracking-tight;
    @apply font-medium;
    @apply text-sm;
    @apply md:text-base;
    @apply p-4;

    &.error {
      @apply text-red-500;
    }

    &.thank-you {
      //
    }

    &.success {
      //
    }
  }
/** ------------------------------------ */

