.question {
    @apply w-10/12;
    @apply mx-auto;
    @apply font-semibold;
    @apply text-lg;
    @apply text-center;
    @apply mb-8;

   
}

.radio-group {
    @apply w-full;

    &.form-section {
      @apply px-0;
    }
}

.label {
    @apply w-full;
    @apply text-sm;
    @apply p-4;
}

.radio-control {
    @apply opacity-0;
}
